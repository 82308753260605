import React from 'react';
import Layout from '../components/layout/Layout';
import { Link } from 'gatsby';
import Button from '../components/Button';

export default () => (
  <Layout>
    <section id="services" className="py-50 lg:pb-10 lg:pt-12">
      <div className="container mx-auto text-center">
        <Link to="/demo">Back to demo</Link>
        <h2 className="text-3xl lg:text-5xl font-semibold">Notifications</h2>
      </div>
      <div className="container mx-auto">
        <p className="text-xl font-semibold">Chris Foster</p>
        <p className="">Head of Surgery</p>
        <p className="mt-6 font-semibold">Notifications</p>
        <div className="mb-4">
          <input className="shadow border rounded px-3 text-gray-700" id="newlistingsmatch" type="checkbox" />
          <label className="text-gray-700 text-sm font-bold mb-2" for="newlistingsmatch"> New listings matches acquisition request </label>
        </div>
        <div className="mb-4">
          <input className="shadow border rounded px-3 text-gray-700" id="newlistings" type="checkbox" />
          <label className="text-gray-700 text-sm font-bold mb-2" for="newlistings"> All new listings </label>
        </div>
        <div className="mb-4">
          <input className="shadow border rounded px-3 text-gray-700" id="newacuqiestions" type="checkbox" />
          <label className="text-gray-700 text-sm font-bold mb-2" for="newacuqiestions"> All new acquisition requests</label>
        </div>
        <div className="mb-4">
          <input className="shadow border rounded px-3 text-gray-700" id="newlistingsrequests" type="checkbox" />
          <label className="text-gray-700 text-sm font-bold mb-2" for="newlistingsrequests"> All new listing requests</label>
        </div>
        <div className="mb-4">
          <input className="shadow border rounded px-3 text-gray-700" id="newpurchases" type="checkbox" />
          <label className="text-gray-700 text-sm font-bold mb-2" for="newpurchases"> All new purchases requests</label>
        </div>
        <div className="mb-4">
          <input className="shadow border rounded px-3 text-gray-700" id="myrequests" type="checkbox" />
          <label className="text-gray-700 text-sm font-bold mb-2" for="myrequests"> My requests updated</label>
        </div>

        <div className="flex items-center justify-between">
          <Button size="sm">Save</Button>
        </div>
      </div>



    </section>
  </Layout>
);


